import { graphql } from 'gatsby'

import { CardTextBlock, FlexCard } from '../Card'
import { EntryLink } from './../EntryLink'
import { AspectImage } from '../Image'
import { gridMap } from '../../utils'

const InfoBlock = ({ backgroundColor, blockWidth, contentRef }) => {
  const { title, text, linksCollection, image } = contentRef

  return (
    <FlexCard
      width={blockWidth ? gridMap[blockWidth] : gridMap['full-width']}
      dark={backgroundColor === 'Blue'}
      action={
        linksCollection?.items.length > 0
          ? linksCollection?.items.map((item) => {
              return <EntryLink {...item} key={item?.sys?.id} />
            })
          : null
      }
      media={image && <AspectImage {...image} />}
    >
      <CardTextBlock title={title} text={text} />
    </FlexCard>
  )
}

export const query = graphql`
  fragment InfoBlock on contentful_InfoBlock {
    __typename
    sys {
      id
    }
    backgroundColor
    blockWidth
    contentRef {
      title
      text
      image {
        ...Asset
      }
      linksCollection(limit: 10) {
        items {
          ...ItemLink
        }
      }
    }
  }
`

export default InfoBlock
